exports.components = {
  "component---src-sites-type-auto-lumy-templates-article-tsx": () => import("./../../../src/sites/typeAuto/lumy/templates/Article.tsx" /* webpackChunkName: "component---src-sites-type-auto-lumy-templates-article-tsx" */),
  "component---src-sites-type-auto-lumy-templates-articles-tsx": () => import("./../../../src/sites/typeAuto/lumy/templates/Articles.tsx" /* webpackChunkName: "component---src-sites-type-auto-lumy-templates-articles-tsx" */),
  "component---src-sites-type-auto-lumy-templates-companies-tsx": () => import("./../../../src/sites/typeAuto/lumy/templates/Companies.tsx" /* webpackChunkName: "component---src-sites-type-auto-lumy-templates-companies-tsx" */),
  "component---src-sites-type-auto-lumy-templates-company-tsx": () => import("./../../../src/sites/typeAuto/lumy/templates/Company.tsx" /* webpackChunkName: "component---src-sites-type-auto-lumy-templates-company-tsx" */),
  "component---src-sites-type-auto-lumy-templates-contact-tsx": () => import("./../../../src/sites/typeAuto/lumy/templates/Contact.tsx" /* webpackChunkName: "component---src-sites-type-auto-lumy-templates-contact-tsx" */),
  "component---src-sites-type-auto-lumy-templates-cookies-policy-tsx": () => import("./../../../src/sites/typeAuto/lumy/templates/CookiesPolicy.tsx" /* webpackChunkName: "component---src-sites-type-auto-lumy-templates-cookies-policy-tsx" */),
  "component---src-sites-type-auto-lumy-templates-cookies-table-tsx": () => import("./../../../src/sites/typeAuto/lumy/templates/CookiesTable.tsx" /* webpackChunkName: "component---src-sites-type-auto-lumy-templates-cookies-table-tsx" */),
  "component---src-sites-type-auto-lumy-templates-home-tsx": () => import("./../../../src/sites/typeAuto/lumy/templates/Home.tsx" /* webpackChunkName: "component---src-sites-type-auto-lumy-templates-home-tsx" */),
  "component---src-sites-type-auto-lumy-templates-not-found-tsx": () => import("./../../../src/sites/typeAuto/lumy/templates/NotFound.tsx" /* webpackChunkName: "component---src-sites-type-auto-lumy-templates-not-found-tsx" */),
  "component---src-sites-type-auto-lumy-templates-search-tsx": () => import("./../../../src/sites/typeAuto/lumy/templates/Search.tsx" /* webpackChunkName: "component---src-sites-type-auto-lumy-templates-search-tsx" */),
  "component---src-sites-type-auto-lumy-templates-terms-tsx": () => import("./../../../src/sites/typeAuto/lumy/templates/Terms.tsx" /* webpackChunkName: "component---src-sites-type-auto-lumy-templates-terms-tsx" */)
}

